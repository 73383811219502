<template>
  <v-container fluid class="pt-0 with-dence-input pay-table-wrapper">
    <v-row>
      <v-dialog
          v-model="showDialog"
          persistent
          max-width="800"
          :retain-focus="false"
      >
        <v-card>
          <v-toolbar color="grey darken-3" dark elevation="1">
            <v-toolbar-title>
              Введіть назву для збереження налаштувань
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text class="pt-4">
            <v-text-field
                hide-details
                filled
                type="text"
                v-model="config_name"
                required
                :class="config_name ? '' : 'req-star'"
                :rules="[v => !!v || 'Це поле є обов’язковим']"
                color="grey"
                label="Назва налаштувань"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed text color="secondary darken-1" :disabled="!config_name"
                   @click.stop.prevent="saveConfig(null, true)" class="button-accept">
              <v-icon left>mdi-content-save</v-icon>
              Зберегти
            </v-btn>
            <v-btn depressed text tile color="grey" @click="closeModal">
              <v-icon left>mdi-close</v-icon>
              Відмінити
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-col cols="12">
        <v-tabs class="custom-tabs" color="success" v-model="tab" style="height: 100%">
          <v-tab>Налаштування</v-tab>
          <v-tab>Результат обробки</v-tab>

          <v-tab-item class="pa-0 pt-1">
            <v-row class="dense-wrapper">
              <v-col cols="6">
                <v-card class="fill-height px-4" :disabled="loading">
                  <v-card-title style="font-size: 1.1rem" class="pt-3 pb-3">
                    Налаштування файлу
                  </v-card-title>
                  <v-row class="dense-wrapper">
                    <v-col :cols="extensionVisible[extension]?.first_row ? 8 : 12">
                      <v-file-input
                          v-model="inputFile"
                          placeholder="Оберіть файл для імпорту абонентів"
                          label="Файл з абонентами"
                          prepend-icon="mdi-paperclip"
                          show-size
                          hide-details
                          filled
                          :color="!inputFile ? 'red' : 'grey'"
                          accept=".xls, .xlsx, .csv, .dbf"
                          @change="onFileChange"
                      >
                      </v-file-input>
                    </v-col>
                    <v-col cols="4" v-if="extensionVisible[extension]?.first_row">
                      <v-text-field
                          hide-details
                          filled
                          label="№ першого рядка даних"
                          v-model="config.first_row"
                          :class="(extensionVisible[extension] ? extensionVisible[extension].first_row : false) && !config.first_row ? 'req-star' : ''"
                          :disabled="extensionVisible[extension] ? !extensionVisible[extension].first_row : true"
                          color="grey"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="dense-wrapper">
                    <v-col :cols="extensionVisible[extension]?.splitter ? 8 : 12">
                      <v-select type="text"
                                hide-details filled
                                label="Збережені налаштування"
                                v-model="importSetting"
                                :items="importSettings"
                                @change="settingsChange"
                                prepend-icon="mdi-file-settings-outline"
                                color="grey"
                      />
                    </v-col>
                    <v-col cols="4" v-if="extensionVisible[extension]?.splitter">
                      <v-text-field
                          hide-details
                          filled
                          type="text"
                          v-model="config.splitter"
                          :class="(extensionVisible[extension] ? extensionVisible[extension].splitter : false) && !config.splitter ? 'req-star' : ''"
                          :disabled="extensionVisible[extension] ? !extensionVisible[extension].splitter : true"
                          color="grey"
                          label="Роздільник"/>
                    </v-col>
                  </v-row>
                  <v-row class="dense-wrapper">
                    <v-col cols="6">
                      <v-btn block depressed color="success" @click="importFile" :loading="loading"
                             :disabled="!inputFile">Обробити
                        файл
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn block depressed color="secondary" @click="saveConfig" :disabled="!settingsIsValid()"
                             v-if="!importSetting">
                        Зберегти налаштування
                      </v-btn>
                      <v-menu
                          transition="slide-y-transition"
                          bottom
                          offset-y
                          v-else
                          close-on-content-click
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              block
                              depressed
                              color="secondary"
                              v-bind="attrs"
                              v-on="on"
                              :disabled="!settingsIsValid()"
                          >
                            Операції із налаштуваннями
                          </v-btn>
                        </template>
                        <v-list class="subtitle-1" nav dense>
                          <v-list-item @click="saveConfig">
                            <v-list-item-icon>
                              <v-icon color="success">mdi-content-save-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Оновити обране налаштування</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="saveNewConfig">
                            <v-list-item-icon>
                              <v-icon color="success">mdi-content-save-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Створити нове налаштування</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="deleteConfigDialog">
                            <v-list-item-icon>
                              <v-icon color="error">mdi-content-save-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Вилучити обране</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="fill-height px-4 pb-3" :disabled="loading">
                  <v-card-title style="font-size: 1.1rem" class="pt-3 pb-3 pl-0">
                    Додаткові налаштування
                  </v-card-title>
                  <v-row class="dense-wrapper">
                    <v-col cols="8">
                      <Bank :value="config.bank" :class_="config.bank ? '' : 'req-star'" :required="true"
                          @autocompleteChange="bankChangeHeader"/>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                          hide-details
                          placeholder="cp1251, cp1252, utf-8 та інші"
                          filled
                          type="text"
                          v-model="config.code_page"
                          :class="(extensionVisible[extension] ? extensionVisible[extension].code_page : false) && !config.code_page ? 'req-star' : ''"
                          :disabled="extensionVisible[extension] ? !extensionVisible[extension].code_page : true"
                          color="grey"
                          label="Кодування файлу"/>
                    </v-col>
                  </v-row>
                  <v-row class="dense-wrapper">
                    <v-col cols="6">
                      <v-switch
                          v-model="config.opl_date_from_file"
                          hide-details
                          :label="config.opl_date_from_file ? 'Дата платежу з файла' : 'Ігнорувати дату платежу із файла'"
                          color="success"
                      ></v-switch>
                    </v-col>
                    <v-col cols="6">
                      <date-component label="Дата оплати" v-model="config.opl_date"
                                      :disabled="config.opl_date_from_file"
                                      :req="!config.opl_date_from_file"
                                      :class_="!config.opl_date_from_file && !config.opl_date ? 'req-star' : ''"
                      ></date-component>
                    </v-col>
                    <v-col cols="6">
                      <v-switch
                          v-model="config.opl_service_from_file"
                          hide-details
                          :label="config.opl_service_from_file ? 'Послуга в окремій колонці' : 'Ігнорувати послугу із файла'"
                          color="success"
                      ></v-switch>
                    </v-col>
                    <v-col cols="6">
                      <v-select :items="services" filled label="Послуга"
                                hide-details
                                :class="!config.opl_service_from_file && !config.service_id ? 'req-star' : ''"
                                v-model="config.service_id"
                                :disabled="config.opl_service_from_file"
                                color="grey"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-tabs color="success">
                  <v-tab>Колонки</v-tab>
                  <v-tab>Послуги</v-tab>
                  <v-tab>Фільтр</v-tab>

                  <v-tab-item>
                    <v-row>
                      <v-col cols="12" class="px-0">
                        <v-card class="pt-1 pb-3 px-0" :disabled="loading">
                          <v-card-text v-if="!extension">
                            <div style="font-size: 1.4rem; font-weight: 500; text-align: center; padding: 120px 30px;">
                              Для відображення налаштування оберіть файл. Налаштування залежать від типу файлу
                            </div>
                          </v-card-text>
                          <template v-if="extension">
                            <v-row class="ma-3">
                              <v-col cols="12" v-if="extension">
                                  <v-row class="pl-3">
                                    <v-col cols="3">
                                      <v-switch
                                          v-model="config.col_person_id_from_file"
                                          hide-details
                                          class="mt-1"
                                          :label="config.col_person_id_from_file ? 'Особовий рахунок в окремій колонці' : 'Особовий рахунок із призначення платежу'"
                                          color="success"
                                      />
                                    </v-col>
                                    <v-col cols="3">
                                      <v-switch
                                          v-model="config.col_payer_from_file"
                                          hide-details
                                          class="mt-1"
                                          :label="config.col_payer_from_file ? 'Платник в окремій колонці' : 'Платник - це власник квартири'"
                                          color="success"
                                      ></v-switch>
                                    </v-col>
                                    <v-col cols="3">
                                      <v-switch
                                          v-model="config.col_address_from_file"
                                          hide-details
                                          class="mt-1"
                                          :label="config.col_address_from_file ? 'Адреса в окремій колонці' : 'Адреса із бази'"
                                          color="success"
                                      ></v-switch>
                                    </v-col>
                                    <v-col cols="3">
                                      <v-switch
                                          v-model="config.positive_sum"
                                          hide-details
                                          class="mt-1"
                                          :label="config.positive_sum ? 'Додатні суми' : 'Усі суми'"
                                          color="success"
                                      ></v-switch>
                                    </v-col>
                                  </v-row>
                              </v-col>
                              <v-col cols="6" v-if="config.col_person_id_from_file">
                                <v-subheader class="align-end mb-1 font-weight-medium text-uppercase grey--text text--darken-1 custom-subheader"
                                             style="height: 20px; font-size: .8rem">
                                  Колонка особовий рахунок
                                  <v-divider style="align-self: center; margin-top: 10px; margin-left: 8px"></v-divider>
                                </v-subheader>
                                <v-row>
                                  <v-col cols="12" v-if="extensionVisible[extension]?.number">
                                    <v-text-field
                                        hide-details
                                        type="text"
                                        filled
                                        single-line
                                        v-model="config.col_person_id_number"
                                        :disabled="!config.col_person_id_from_file || extensionVisible[extension] ? !getExtensionConfig('number') : true"
                                        label="№ колонки"
                                        color="grey"/>
                                  </v-col>
                                  <v-col cols="12" v-if="extensionVisible[extension]?.name">
                                    <v-text-field
                                        hide-details
                                        filled
                                        type="text"
                                        v-model="config.col_person_id_name"
                                        :disabled="!config.col_person_id_from_file || extensionVisible[extension] ? !getExtensionConfig('name') : true"
                                        label="Назва колонки"
                                        color="grey"/>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="6" v-if="config.opl_date_from_file">
                                <v-subheader class="align-end mb-1 font-weight-medium text-uppercase grey--text text--darken-1 custom-subheader"
                                             style="height: 20px; font-size: .8rem">
                                  Колонка дата платежу
                                  <v-divider style="align-self: center; margin-top: 10px; margin-left: 8px"></v-divider>
                                </v-subheader>
                                <v-row>
                                  <v-col cols="12" v-if="extensionVisible[extension]?.number">
                                    <v-text-field
                                        hide-details
                                        filled
                                        type="text"
                                        v-model="config.col_opl_date_number"
                                        :disabled="extensionVisible[extension] ? !extensionVisible[extension].number : true"
                                        label="№ колонки"
                                        color="grey"/>
                                  </v-col>
                                  <v-col cols="12" v-if="extensionVisible[extension]?.name">
                                    <v-text-field
                                        hide-details
                                        filled
                                        type="text"
                                        v-model="config.col_opl_date_name"
                                        :disabled="extensionVisible[extension] ? !extensionVisible[extension].name : true"
                                        label="Назва колонки"
                                        color="grey"/>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="6">
                                <v-subheader class="align-end mb-1 font-weight-medium text-uppercase grey--text text--darken-1 custom-subheader"
                                             style="height: 20px; font-size: .8rem">
                                  Колонка сума
                                  <v-divider style="align-self: center; margin-top: 10px; margin-left: 8px"></v-divider>
                                </v-subheader>
                                <v-row>
                                  <v-col cols="12" v-if="extensionVisible[extension]?.number">
                                    <v-text-field
                                        hide-details
                                        filled
                                        type="text"
                                        v-model="config.col_sum_number"
                                        :disabled="extensionVisible[extension] ? !extensionVisible[extension].number : true"
                                        label="№ колонки"
                                        color="grey"/>
                                  </v-col>
                                  <v-col cols="12" v-if="extensionVisible[extension]?.name">
                                    <v-text-field
                                        hide-details
                                        filled
                                        type="text"
                                        v-model="config.col_sum_name"
                                        :disabled="extensionVisible[extension] ? !extensionVisible[extension].name : true"
                                        label="Назва колонки"
                                        color="grey"/>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="6" v-if="config.col_payer_from_file">
                                <v-subheader class="align-end mb-1 font-weight-medium text-uppercase grey--text text--darken-1 custom-subheader"
                                             style="height: 20px; font-size: .8rem">
                                  Колонка платник
                                  <v-divider style="align-self: center; margin-top: 10px;"></v-divider>
                                </v-subheader>
                                <v-row>
                                  <v-col cols="12" v-if="extensionVisible[extension]?.number">
                                    <v-text-field
                                        hide-details
                                        filled
                                        type="text"
                                        v-model="config.col_payer_number"
                                        label="№ колонки"
                                        :disabled="!config.col_payer_from_file || extensionVisible[extension] ? !getExtensionConfig('number') : true"
                                        color="grey"/>
                                  </v-col>
                                  <v-col cols="12" v-if="extensionVisible[extension]?.name">
                                    <v-text-field
                                        hide-details
                                        filled
                                        type="text"
                                        v-model="config.col_payer_name"
                                        :disabled="!config.col_payer_from_file || extensionVisible[extension] ? !getExtensionConfig('name') : true"
                                        label="Назва колонки"
                                        color="grey"/>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="6" v-if="config.col_address_from_file">
                                  <v-subheader class="align-end mb-1 font-weight-medium text-uppercase grey--text text--darken-1 custom-subheader"
                                               style="height: 20px; font-size: .8rem">
                                    Колонка адреса
                                    <v-divider style="align-self: center; margin-top: 10px; margin-left: 8px"></v-divider>
                                  </v-subheader>
                                  <v-row>
                                    <v-col cols="12" v-if="extensionVisible[extension]?.number">
                                      <v-text-field
                                          hide-details
                                          filled
                                          type="text"
                                          v-model="config.col_address_number"
                                          label="№ колонки"
                                          :disabled="!config.col_address_from_file || extensionVisible[extension] ? !getExtensionConfig('number') : true"
                                          color="grey"/>
                                    </v-col>
                                    <v-col cols="12" v-if="extensionVisible[extension]?.name">
                                      <v-text-field
                                          hide-details
                                          filled
                                          type="text"
                                          v-model="config.col_address_name"
                                          :disabled="!config.col_address_from_file || extensionVisible[extension] ? !getExtensionConfig('name') : true"
                                          label="Назва колонки"
                                          color="grey"/>
                                    </v-col>
                                  </v-row>
                              </v-col>
                              <v-col cols="6">
                                <v-subheader class="align-end mb-1 font-weight-medium text-uppercase grey--text text--darken-1 custom-subheader"
                                             style="height: 20px; font-size: .8rem">
                                  Колонка банківський рахунок
                                  <v-divider style="align-self: center; margin-top: 10px;"></v-divider>
                                </v-subheader>
                                <v-row>
                                  <v-col cols="12" v-if="extensionVisible[extension]?.number">
                                    <v-text-field
                                        hide-details
                                        filled
                                        type="text"
                                        v-model="config.col_bank_account_number"
                                        label="№ колонки"
                                        :disabled="extensionVisible[extension] ? !getExtensionConfig('number') : true"
                                        color="grey"/>
                                  </v-col>
                                  <v-col cols="12" v-if="extensionVisible[extension]?.name">
                                    <v-text-field
                                        hide-details
                                        filled
                                        type="text"
                                        v-model="config.col_bank_account_name"
                                        :disabled="extensionVisible[extension] ? !getExtensionConfig('name') : true"
                                        label="Назва колонки"
                                        color="grey"/>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12"  v-if="config.opl_service_from_file && extension">
                                <v-subheader class="align-end mb-1 font-weight-medium text-uppercase grey--text text--darken-1 custom-subheader"
                                             style="height: 20px; font-size: .8rem">
                                  Колонка послуга
                                  <v-divider style="align-self: center; margin-top: 10px; margin-left: 8px"></v-divider>
                                </v-subheader>
                                <v-row>
                                  <v-col cols="6" v-if="extensionVisible[extension]?.number">
                                    <v-text-field
                                        hide-details
                                        filled
                                        type="text"
                                        v-model="config.col_service_number"
                                        :disabled="extensionVisible[extension] ? !extensionVisible[extension].number : true"
                                        label="№ колонки"
                                        v-decimal
                                        color="grey"/>
                                  </v-col>
                                  <v-col cols="6" v-if="extensionVisible[extension]?.name">
                                    <v-text-field
                                        hide-details
                                        filled
                                        type="text"
                                        v-model="config.col_service_name"
                                        :disabled="extensionVisible[extension] ? !extensionVisible[extension].name : true"
                                        label="Назва колонки"
                                        color="grey"/>
                                  </v-col>
                                  <v-col cols="6" class="d-flex">
                                    <v-switch
                                        class="align-self-center mr-4 mt-1"
                                        v-model="config.service_by_code"
                                        hide-details
                                        :label="config.service_by_code ? 'Відповідність послуги по коду' : 'Відповідність послуги по назві'"
                                        color="success"
                                    />
                                    <v-switch
                                        v-model="config.change_empty_service_by_all"
                                        hide-details class="mt-1 align-self-center"
                                        :label="config.change_empty_service_by_all ? 'Заміняти невизначену послугу на авто.поділ' : 'Не заміняти невизначену послугу'"
                                        color="success"
                                    />
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12">
                                  <v-subheader class="align-end mb-1 font-weight-medium text-uppercase grey--text text--darken-1 custom-subheader"
                                               style="height: 20px; font-size: .8rem">
                                    Колонка призначення платежу
                                    <v-divider style="align-self: center; margin-top: 10px; margin-left: 8px"></v-divider>
                                  </v-subheader>
                                  <v-row>
                                    <v-col cols="6" v-if="extensionVisible[extension]?.number">
                                      <v-text-field
                                          hide-details
                                          filled
                                          type="text"
                                          v-model="config.col_purpose_of_payment_number"
                                          :disabled="extensionVisible[extension] ? !extensionVisible[extension].number : true"
                                          label="№ колонки"
                                          color="grey"/>
                                    </v-col>
                                    <v-col cols="6" v-if="extensionVisible[extension]?.name">
                                      <v-text-field
                                          hide-details
                                          filled
                                          type="text"
                                          v-model="config.col_purpose_of_payment_name"
                                          :disabled="extensionVisible[extension] ? !extensionVisible[extension].name : true"
                                          label="Назва колонки"
                                          color="grey"/>
                                    </v-col>
                                    <v-col cols="3">
                                      <v-text-field
                                          hide-details
                                          filled
                                          type="text"
                                          v-model="config.col_purpose_of_payment_spliter"
                                          label="Роздільник"
                                          color="grey"/>
                                    </v-col>
                                    <v-col cols="3">
                                      <v-text-field
                                          hide-details
                                          filled
                                          type="text"
                                          v-model="config.col_purpose_of_payment_arr_number"
                                          label="Номер елемента"
                                          color="grey"/>
                                    </v-col>
                                  </v-row>
                              </v-col>
                            </v-row>
                          </template>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row>
                      <v-col cols="12" class="px-0">
                        <v-card class="pt-1 pb-3 px-0" :disabled="loading">
                          <v-card-text>
                            <v-btn small depressed color="grey lighten-3" class="ml-2 mr-2" @click="addNewServiceRow">
                              <v-icon left size="22">mdi-plus</v-icon>
                              Додати рядок
                            </v-btn>
                            <v-btn small depressed color="error lighten-3" @click="deleteRowsFromServices"
                                   :disabled="!selectedServicesRows.length">
                              <v-icon left size="22">
                                mdi-delete
                              </v-icon>
                              Видалити рядок(-ки)
                            </v-btn>
                          </v-card-text>
                          <v-card-text class="py-3" v-for="item in config.services_conformity"
                                       :key="item.row_num">
                            <v-row>
                              <v-col cols="3" class="d-flex">
                                <v-checkbox class="select-checkbox align-self-center mt-0 ml-1" color="success" hide-details
                                            :ripple="false"
                                            @change="selectServiceRow(item.row_num)"/>
                                <v-text-field
                                    hide-details
                                    filled
                                    type="text"
                                    v-model="item.code"
                                    label="Код"
                                    color="grey"
                                    class="ml-4"/>
                              </v-col>
                              <v-col cols="3">
                                <v-text-field
                                    hide-details
                                    filled
                                    type="text"
                                    v-model="item.name"
                                    label="Назва"
                                    color="grey"
                                    class="ml-4"/>
                              </v-col>
                              <v-col cols="6">
                                <v-select :items="services" filled label="Послуга"
                                          hide-details
                                          :class="!item.service_id ? 'req-star' : ''"
                                          v-model="item.service_id"
                                          color="grey"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row>
                      <v-col cols="12" class="px-0">
                        <v-card class="pt-1 pb-3 px-0" :disabled="loading">
                          <v-card-text>
                            <v-btn small depressed color="grey lighten-3" class="ml-2 mr-2" @click="addNewFilterRow">
                              <v-icon left size="22">mdi-plus</v-icon>
                              Додати рядок
                            </v-btn>
                            <v-btn small depressed color="error lighten-3" @click="deleteRowsFromFilters"
                                   :disabled="!selectedFiltersRows.length">
                              <v-icon left size="22">
                                mdi-delete
                              </v-icon>
                              Видалити рядок(-ки)
                            </v-btn>
                          </v-card-text>
                          <v-card-text class="py-3" v-for="item in config.filters"
                                       :key="item.row_num">
                            <v-row>
                              <v-col cols="3" class="d-flex" v-if="extensionVisible[extension]?.number">
                                <v-checkbox class="select-checkbox align-self-center mt-0 ml-1" color="success" hide-details
                                            :ripple="false"
                                            @change="selectFilterRow(item.row_num)"/>
                                <v-text-field
                                    hide-details
                                    filled
                                    type="text"
                                    v-model="item.col_number"
                                    :class="!item.col_number ? 'req-star' : ''"
                                    label="Номер колонки"
                                    color="grey"
                                    class="ml-4"/>
                              </v-col>
                              <v-col cols="3" class="d-flex" v-if="extensionVisible[extension]?.name">
                                <v-checkbox class="select-checkbox align-self-center mt-0 ml-1" color="success" hide-details
                                            :ripple="false"
                                            @change="selectFilterRow(item.row_num)"/>
                                <v-text-field
                                    hide-details
                                    filled
                                    type="text"
                                    v-model="item.col_name"
                                    label="Назва колонки"
                                    :class="!item.col_name ? 'req-star' : ''"
                                    color="grey"
                                    class="ml-4"/>
                              </v-col>
                              <v-col cols="3">
                                <v-select :items="value_types" filled label="Тип поля"
                                          hide-details
                                          :class="!item.col_type ? 'req-star' : ''"
                                          v-model="item.col_type"
                                          color="grey"
                                />
                              </v-col>
                              <v-col cols="2">
                                <v-select :items="operations" filled label="Операція"
                                          hide-details
                                          :class="!item.operation ? 'req-star' : ''"
                                          v-model="item.operation"
                                          color="grey"
                                />
                              </v-col>
                              <v-col cols="4">
                                <div class="d-flex">
                                  <div :class="{'flex-50': item.operation === 'between', 'flex-100': item.operation !== 'between'}">
                                    <template v-if="item.col_type === 'numeric'">
                                      <v-text-field
                                          hide-details
                                          filled
                                          type="text"
                                          v-model="item.value_from"
                                          label="Значення з"
                                          v-decimal
                                          color="grey"/>
                                    </template>
                                    <template v-else-if="item.col_type === 'list'">
                                      <v-text-field
                                          hide-details
                                          filled
                                          type="text"
                                          v-model="item.value_from"
                                          label="Значення з"
                                          color="grey"/>
                                    </template>
                                    <template v-else>
                                      <v-text-field
                                          hide-details
                                          filled
                                          type="text"
                                          v-model="item.value_from"
                                          label="Значення з"
                                          color="grey"/>
                                    </template>
                                  </div>
                                  <div style="flex: 1" v-if="item.operation === 'between'">
                                    <template v-if="item.col_type === 'numeric'">
                                      <v-text-field
                                          hide-details
                                          filled
                                          type="text"
                                          v-model="item.value_to"
                                          label="Значення по"
                                          color="grey"
                                          v-decimal
                                          class="ml-4"/>
                                    </template>
                                    <template v-else-if="item.col_type === 'list'">
                                      <v-text-field
                                          hide-details
                                          filled
                                          type="text"
                                          v-model="item.value_to"
                                          label="Значення по"
                                          color="grey"
                                          class="ml-4"/>
                                    </template>
                                    <template v-else>
                                      <v-text-field
                                          hide-details
                                          filled
                                          type="text"
                                          v-model="item.value_to"
                                          label="Значення по"
                                          color="grey"
                                          class="ml-4"/>
                                    </template>
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="pa-0 pt-1" :disabled="loading">
            <v-row class="dense-wrapper">
              <v-col cols="12" class="mt-2">
                <v-card elevation="2" :disabled="loading">
                  <v-toolbar tile elevation="0" outlined dense style="border: none">
                    <v-toolbar-title class="subtitle-1">
                      <strong class="grey--text text--darken-2">{{ inputFile ? inputFile.name : '' }}</strong> на суму:
                      <strong>{{ total_sum | formatNumber }} ({{ total_count }}). </strong>
                      <span> Знайдені: <strong class="success--text">{{ total_sum_correct | formatNumber }} ({{ total_count_correct }})</strong></span>
                      <span>. Не знайдені: <strong class="error--text">{{ total_sum_error | formatNumber }} ({{ total_count_error }})</strong></span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items class="shrink">
                      <v-row class="align-center">
                        <v-btn fab depressed small class="mr-2" @click="saveDocument" :disabled="!documentReady">
                          <v-icon color="grey darken-1">
                            mdi-content-save
                          </v-icon>
                        </v-btn>
                        <v-btn fab depressed small @click="deleteDialog">
                          <v-icon color="error">
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-row>
                    </v-toolbar-items>
                  </v-toolbar>
                </v-card>
              </v-col>
              <v-col cols="12" class="pt-1 pb-2">
                <v-menu
                    v-model="showRowDescription"
                    :position-x="x"
                    :position-y="y"
                    absolute
                    offset-y
                    :close-on-content-click="false"
                >
                  <v-card class="px-4 pt-2 pb-4" max-width="400">
                    <div>
                      <div class="font-weight-medium" style="font-size: .84rem">Призначення платежу</div>
                      <div class="grey--text text--darken-2 grey lighten-4 pa-3" style="font-size: .9rem">
                        {{ rowDescription?.purpose_of_payment }}
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="font-weight-medium" style="font-size: .84rem">Банківський рахунок</div>
                      <div class="grey--text text--darken-2 grey lighten-4 pa-3" style="font-size: .9rem">
                        {{ rowDescription?.bank_account }}
                      </div>
                    </div>
                  </v-card>
                </v-menu>
                <v-card tile flat color="grey lighten-5" class="d-flex" :disabled="loading">
                  <v-btn small depressed color="grey lighten-3" @click="addNewRow" class="mr-2">
                    <v-icon left size="22">
                      mdi-plus
                    </v-icon>
                    Додати рядок
                  </v-btn>
                  <v-btn small depressed color="error lighten-3" @click="deleteRows" :disabled="!selectedRows.length">
                    <v-icon left size="22">
                      mdi-delete
                    </v-icon>
                    Видалити рядок(-ки)
                  </v-btn>
                  <div style="align-self: center">
                    <v-switch hide-details v-model="onlyErrors" @change="changeOnlyError" class="mt-0 pt-0 ml-2"
                              color="success"
                              :label="onlyErrors ? 'Лише помилкові рядки' : 'Усі рядки'"/>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="fill-height pay-table-wrapper" :disabled="loading" style="overflow-x: auto;">
                  <div class="pay-table" v-if="!loading">
                    <div class="pay-table-header">
                      <div class="pay-table-row">
                        <div class="pay-table-col"></div>
                        <div class="pay-table-col">
                          №
                        </div>
                        <div class="pay-table-col editable">
                          Особовий
                        </div>
                        <div class="pay-table-col">
                          Адреса
                        </div>
                        <div class="pay-table-col">
                          Платник
                        </div>
                        <div class="pay-table-col editable">
                          Дата
                        </div>
<!--                        <div class="pay-table-col">-->
<!--                          Банк-->
<!--                        </div>-->
                        <div class="pay-table-col">
                          Сальдо
                        </div>
                        <div class="pay-table-col editable">
                          Оплачено
                        </div>
                        <div class="pay-table-col editable">
                          Послуга
                        </div>
                        <div class="pay-table-col">
                        </div>
                      </div>
                    </div>
                    <div class="pay-table-body">
                      <div
                          class="pay-table-row"
                          :class="{'expandable': item.services.length || item.service_id === all_services,
                           'expanded': item.row_num === expand_row_num}"
                          v-for="(item, index) in filtered_items" :key="`item-${item.row_num}-${index}`">
                        <template v-if="!item.services.length && !(item.service_id === all_services)">
                          <div class="pay-table-col"
                               :class="{'col-error': item.error}"
                               style="border-left: 6px solid #4caf50d1; padding-left: 10px !important;">
                            <v-checkbox class="select-checkbox" color="success" hide-details :ripple="false"
                                        @change="selectRow(item.row_num)"></v-checkbox>
                          </div>
                          <div class="pay-table-col">
                            {{ item.row_num }}
                          </div>
                          <div class="pay-table-col editable">
                              <AUC_FlatSearch
                                      :row_num="item.row_num"
                                      :flat_id="item.flat_id"
                                      :text_value="item.person_id"
                                      :text_value_key="'person_id'"
                                      :class="{'error-input': !item.person_id}"
                                      @selectChange="flatChange"
                              />
                          </div>
                          <div class="pay-table-col">
                            {{ item.address }}
                          </div>
                          <div class="pay-table-col">
                            {{ item.payer }}
                          </div>
                          <div class="pay-table-col editable" style="position:relative;">
                            <input type="text" :value="item.pay_date | formatDate" readonly
                                   :class="{'error-input': !item.pay_date}">
                            <div style="position:absolute; right: 18%; top: 50%; transform: translateY(-66%);">
                              <DateComponentIcon v-model="item.pay_date" :row_num="item.row_num"
                                                 @changeDate="changeDate"/>
                            </div>
                          </div>
                          <div class="pay-table-col" :class="{'negative-balance': item.balance < 0,
                                                      'positive-balance': item.balance > 0,
                                                      'equal-balance': item.balance === 0
                                                      }"
                          >
                            {{ item.balance | formatToFixed }}
                          </div>
                          <div class="pay-table-col editable">
                            <input type="text"
                                   :value="item.sum"
                                   @input="item.sum = +$event.target.value || 0"
                                   :class="{'error-input': !item.sum}"
                                   v-decimal>
                          </div>
                          <div class="pay-table-col editable">
                            <v-icon style="width: 28px" :color="getAccountIcon(item.icon, 'color')" size="24"
                                    class="mr-3">
                              {{ getAccountIcon(item.icon, 'icon') }}
                            </v-icon>
                            <div style="line-height: 25px; width: 220px"
                                 :class="{'error-input-select': !item.service_id, 'input-select': item.service_id}">
                              <v-select :items="services"
                                        hide-details
                                        v-model="item.service_id"
                                        :class="{'error-input': !item.service_id, 'table-input': true}"
                                        @change="changeService($event, item.row_num, false)"
                                        color="grey"/>
                            </div>
                          </div>
                          <div class="pay-table-col">
                            <v-btn small depressed icon @click.stop="onDescriptionClick($event, item)">
                              <v-icon>mdi-information-variant-circle-outline</v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <template v-else>
                          <div class="pay-table-row-expand-header"
                               :class="{'expanded': item.row_num === expand_row_num}">
                            <div class="pay-table-col"
                                 :class="{'col-error': item.error}"
                                 style="border-left: 6px solid #737373; padding-left: 10px !important;">
                              <v-checkbox class="select-checkbox" color="success" hide-details :ripple="false"
                                          @change="selectRow(item.row_num)"></v-checkbox>
                            </div>
                            <div class="pay-table-col">
                              {{ item.row_num }}
                            </div>
                            <div class="pay-table-col editable">
                                <AUC_FlatSearch
                                        :row_num="item.row_num"
                                        :flat_id="item.flat_id"
                                        :text_value="item.person_id"
                                        :text_value_key="'person_id'"
                                        :class="{'error-input': !item.person_id}"
                                        @selectChange="flatChange"
                                />
                            </div>
                            <div class="pay-table-col">
                              {{ item.address }}
                            </div>
                            <div class="pay-table-col">
                              {{ item.payer }}
                            </div>
                            <div class="pay-table-col editable" style="position:relative;">
                              <input type="text" :value="item.pay_date | formatDate" readonly
                                     :class="{'error-input': !item.pay_date}">
                              <div style="position:absolute; right: 18%; top: 50%; transform: translateY(-66%);">
                                <DateComponentIcon
                                    v-model="item.pay_date"
                                    :row_num="item.row_num"
                                    @changeDate="changeDate"
                                />
                              </div>
                            </div>
                            <div class="pay-table-col" :class="{'negative-balance': item.balance < 0,
                                                      'positive-balance': item.balance > 0,
                                                      'equal-balance': item.balance === 0
                                                      }"
                            >
                              {{ item.balance | formatNumber }}
                            </div>
                            <div class="pay-table-col editable">
                              <input
                                  type="text"
                                  :value="item.sum"
                                  @input="item.sum = +$event.target.value || 0"
                                  @change="changeSum(item.row_num, null, item.sum)"
                                  :class="{'error-input': !item.sum || item.error}"
                                  v-decimal>
                            </div>
                            <div class="pay-table-col editable">
                              <v-btn icon small class="mr-3" @click="expandRow(item.row_num)">
                                <v-icon>
                                  {{
                                    item.row_num === expand_row_num ? 'mdi-arrow-up-bold-circle-outline' : 'mdi-arrow-down-bold-circle-outline'
                                  }}
                                </v-icon>
                              </v-btn>
                              <div style="line-height: 25px; max-width: 220px">
                                <v-select :items="services"
                                          hide-details
                                          v-model="item.service_id"
                                          class="table-input"
                                          :class="{'error-input': !item.service_id}"
                                          @change="changeService($event, item.row_num, false)"
                                          color="grey"/>
                              </div>
                            </div>
                          </div>
                          <div class="pay-table-row-expand-body" :class="{'expanded': item.row_num === expand_row_num}">
                            <div
                                class="pay-table-expand-body-row"
                                v-for="(sub_item, sub_idx) in item.services"
                                :key="`sub-item-${item.row_num}-${sub_idx}`">
                              <div class="pay-table-col"></div>
                              <div class="pay-table-col" :class="{'negative-balance': sub_item.balance < 0,
                                                      'positive-balance': sub_item.balance > 0,
                                                      'equal-balance': sub_item.balance === 0
                                                      }"
                              >
                                {{ sub_item.balance | formatNumber }}
                              </div>
                              <div class="pay-table-col">
                                <input
                                    type="text"
                                    :value="sub_item.sum"
                                    :class="{'error-input': item.error}"
                                    v-decimal
                                    @input="subitemInput($event, sub_item, item)"
                                    @change="changeSum(item.row_num, 1, sub_item.sum)"
                                >
                              </div>
                              <div class="pay-table-col">
                                <div>
                                  <v-icon style="width: 28px" :color="getAccountIcon(sub_item.icon, 'color')" size="20"
                                          class="mr-3">
                                    {{ getAccountIcon(sub_item.icon, 'icon') }}
                                  </v-icon>
                                </div>
                                <div style="line-height: 25px; width: 220px"
                                     :class="{'error-input-select': !sub_item.service_id}">
                                  <v-select :items="services"
                                            hide-details
                                            v-model="sub_item.service_id"
                                            class="table-input"
                                            readonly
                                            @change="changeService($event, item.row_num, true)"
                                            color="grey"/>
                                </div>
                              </div>
                              <div class="pay-table-col">

                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="pay-table-pagination d-flex justify-start" v-if="(filtered_items || []).length">
                      <div class="pa-3 d-flex align-center justify-start">
                        <v-btn text depressed icon class="mr-1"
                               @click.stop="pageStartOrEnd(true)"
                               :disabled="items_per_page > table_items.length || page === 1"
                        >
                          <v-icon>mdi-chevron-double-left</v-icon>
                        </v-btn>
                        <v-btn text depressed icon
                               @click.stop="changePage(true)"
                               :disabled="page === 1"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <div class="mx-2">
                          <v-chip>
                            {{ `Сторінка: ${page} із ${pages}` }}
                          </v-chip>
                        </div>
                        <v-menu top :close-on-click="true" :close-on-content-click="true">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" class="mr-2">
                              {{ `${items_per_page} на стор.` }}
                            </v-chip>
                          </template>
                          <v-list>
                            <v-list-item
                                v-for="(item, index) in items_per_page_list"
                                :key="index"
                                @click="setItemsPerPage(item)"
                            >
                              <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <v-btn text depressed icon class="mr-1"
                               @click.stop="changePage(false)"
                               :disabled="items_per_page > table_items.length || page === pages"
                        >
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                        <v-btn text depressed icon
                               @click.stop="pageStartOrEnd(false)"
                               :disabled="page === pages"
                        >
                          <v-icon>mdi-chevron-double-right</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <div v-if="!loading && !table_items.length" class="table-no-data">
                    Для внесення оплат скористайтесь командою "Додати рядок"
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {getAccountIcon} from "@/utils/icons";
import {maxBy} from "lodash";
import paymentAPI from "@/utils/axios/payments";
import {ALERT_SHOW} from "@/store/actions/alert";
import fileImportAPI from "@/utils/axios/import_files"
import {formatDate} from "@/filters";
import {QUESTION_SHOW} from "@/store/actions/question";

const modalDeleteId = 'clear_imported_table'

export default {
  name: "PaymentImport",
  components: {
    DateComponentIcon: () => import("@/components/tableComponent/DateComponentIcon"),
    AUC_FlatSearch: () => import("@/components/tableComponent/AUC_FlatSearch.vue"),
    Bank: () => import("@/components/autocomplite/Bank")
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelect',
      month: 'getCurrentMonth',
      settings: 'getGeneralSettings',
      modalAnswer: 'question_answer'
    }),
    all_services() {
      return (this.settings.all_services || {}).value || 0
    },
    // filtered_items() {
    //   return this.onlyErrors ? this.table_items.filter(item => item.error) : this.table_items
    // },
    filtered_items() {
      if (this.onlyErrors) {
        return this.table_items.filter(item => item.error).slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
      } else {
        return this.table_items.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
      }
    }
  },
  data() {
    return {
      showRowDescription: false,
      x: 0,
      y: 0,
      rowDescription: null,
      tab: 0,
      createNew: false,
      inputFile: null,
      importSetting: null,
      importSettings: [],
      showDialog: false,
      config_name: '',
      config_id: null,
      config: {
        bank: null,
        first_row: null,
        splitter: null,
        opl_date: null,
        opl_date_from_file: false,
        col_person_id_number: null,
        col_person_id_name: null,
        col_person_id_from_file: true,
        col_opl_date_number: null,
        col_opl_date_name: null,
        col_sum_number: null,
        col_sum_name: null,
        col_payer_number: null,
        col_payer_name: null,
        col_payer_from_file: true,
        col_purpose_of_payment_number: null,
        col_purpose_of_payment_name: null,
        opl_service_from_file: true,
        col_service_number: null,
        col_service_name: null,
        col_bank_account_number: null,
        col_bank_account_name: null,
        change_empty_service_by_all: false,
        positive_sum: false,
        service_by_code: true,
        service_id: null,
        services_conformity: [],
        filters: []
      },
      loading: false,
      table_items: [],
      total_sum: 0,
      total_count: 0,
      total_sum_error: 0,
      total_count_error: 0,
      total_sum_correct: 0,
      total_count_correct: 0,
      selectedRows: [],
      selectedServicesRows: [],
      selectedFiltersRows: [],
      expand_row_num: 0,
      errors: [],
      extension: null,
      extensionVisible: {
        xls: {number: true, name: false, splitter: false, first_row: true, code_page: false},
        xlsx: {number: true, name: false, splitter: false, first_row: true, code_page: false},
        csv: {number: true, name: false, splitter: true, first_row: true, code_page: true},
        dbf: {number: false, name: true, splitter: false, first_row: false, code_page: true},
      },
      onlyErrors: false,
      pages: 0,
      items_per_page: 12,
      page: 0,
      items_per_page_list: [10, 12, 14, 16, 18, 20, 25, 50],
      operations: [
        {text: '=', value: 'equal'},
        {text: '>', value: 'grater'},
        {text: '<', value: 'less'},
        {text: '>=', value: 'grater_equal'},
        {text: '<=', value: 'less_equal'},
        {text: 'Між', value: 'between'},
        {text: 'в списку', value: 'list'},
      ],
      value_types: [
        {text: 'Число', value: 'numeric'},
        {text: 'Рядок', value: 'string'},
        {text: 'Булево', value: 'boolean'},
      ]
    }
  },
  methods: {
    onDescriptionClick(e, item) {
      this.showRowDescription = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.rowDescription = item
        this.showRowDescription = true
      })
    },
    subitemInput(e, sub_item, item) {
      sub_item.sum = +e.target.value || 0
      item.edited = true
    },
    changeOnlyError() {
      this.$nextTick(() => {
        this.page = 1
        if (this.onlyErrors) {
          this.setPageSplitter((this.filtered_items || []).length)
        } else (
            this.setPageSplitter()
        )
      })
    },
    changePage(left) {
      if (left) {
        this.page = this.page === 1 ? 1 : this.page - 1
      } else {
        this.page = this.page === this.pages ? this.pages : this.page + 1
      }
    },
    setPageSplitter(count) {
      let counter
      if (this.onlyErrors) {
        counter = count === undefined ? this.table_items.filter(item => item.error).length : count
      } else {
        counter = count === undefined ? this.table_items.length : count
      }

      this.pages = Math.ceil(counter / this.items_per_page)

      this.page = this.pages ? 1 : 0

      if (this.pages * this.items_per_page - this.items_per_page > counter) {
        this.pages -= 1
      }
    },
    setItemsPerPage(per_page) {
      this.items_per_page = per_page
      this.setPageSplitter()
    },
    pageStartOrEnd(start) {
      if (start) {
        this.page = 1
      } else {
        this.page = this.pages
      }
    },
    getExtensionConfig(col) {
      const ext = this.extension
      if (ext) {
        if (this.extensionVisible[ext]) {
          return this.extensionVisible[ext][col]
        } else {
          return true
        }
      }
      return true
      // this.extensionVisible[extension].name
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: 'Очистити таблицю із завантаженими оплатами?',
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    getAccountIcon,
    getConfig() {
      fileImportAPI.get_config()
          .then(response => response.data)
          .then(data => {
            this.importSettings = data
          })
    },
    settingsChange() {
      if (this.importSetting) {
        const setting = this.importSettings.find(item => item.value === this.importSetting)
        if (setting) {
          this.createNew = false
          this.config = Object.assign({}, JSON.parse(setting.data))
          if (!this.config.services_conformity) {
            this.config = Object.assign({}, this.config, {services_conformity: []})
          }
        }
      }
    },
    documentReady() {
      let errors = 0
      this.table_items.forEach(item => {
        if (item.error) {
          errors += 1
        }
        if (!item.flat_id || !item.service_id || !item.bank) {
          errors += 1
        }
      })

      return !errors
    },
    importFile() {
      if (this.inputFile && this.settingsIsValid()) {
        const formData = new FormData()
        this.loading = true
        formData.append('config', JSON.stringify(this.config))
        formData.append('file', this.inputFile)
        fileImportAPI.upload_payment_file(formData)
            .then(response => response.data)
            .then(data => {
              this.table_items = data.map((item, idx) => {
                return Object.assign(item, {row_num: idx + 1, error: false})
              })
              this.table_items.forEach(item => {
                item.error = (!item.flat_id || !item.service_id || !item.bank)
              })
              this.getTotals()

              this.tab = 1

              this.setPageSplitter()

              this.$store.dispatch(ALERT_SHOW, {
                text: 'Дані імпортовано успішно. При необхідності виправіть помилки та запишіть результат',
                color: 'success'
              })
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.loading = false
            })
      } else {
        if (!this.settingsIsValid()) {
          this.$store.dispatch(ALERT_SHOW, {text: 'Виправте помилки у налаштуваннях, вони виділені червоним кольором', color: 'error'})
        } else {
          this.$store.dispatch(ALERT_SHOW, {text: 'Оберіть файл та вкажіть налаштування', color: 'error'})
        }
      }
    },
    onFileChange() {
      if (this.inputFile) {
        const file_name = this.inputFile.name
        this.extension = file_name.substr(file_name.lastIndexOf('.') + 1).toLowerCase()
      } else {
        this.extension = null
      }
    },
    settingsIsValid() {
      if (!this.config.bank) return false
      if (!this.config.opl_date_from_file && !this.config.opl_date) return false
      if (!this.config.opl_service_from_file && !this.config.service_id) return false
      if ((this.extensionVisible[this.extension] ? this.extensionVisible[this.extension].splitter : false) && !this.config.splitter) {
        return false
      }
      if ((this.extensionVisible[this.extension] ? this.extensionVisible[this.extension].first_row : false) && !this.config.first_row) {
        return false
      }

      const sum_col_number = this.extensionVisible[this.extension] ? this.extensionVisible[this.extension].number : false
      const sum_col_name = this.extensionVisible[this.extension] ? this.extensionVisible[this.extension].name : false

      if (sum_col_number && !this.config.col_sum_number) return false
      if (sum_col_name && !this.config.col_sum_name) return false

      const services_error = this.config.services_conformity.filter(item => (!item.code && !item.name) || !item.service_id)
      if (services_error.length) return false

      const filters_error = this.config.filters.filter(item => (!item.col_number && !item.col_name) || !item.col_type || !item.operation || (!item.value_from && !item.value_to))
      if (filters_error.length) return false
      return true
    },
    deleteConfigDialog() {
      const payload = {
        text: `Вилучити збережене налаштуання імпорту (${this.config_name})?`,
        accept_button: true,
        id: 'delete_config'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    deleteConfig() {
      if (!this.importSetting) return
      if (this.createNew) return;

      fileImportAPI.delete_payment_import_config({config_id: this.importSetting})
          .then(response => response.data)
          .then(data => {
            if (data.id) {
              this.config = {
                bank: null,
                first_row: null,
                splitter: null,
                opl_date: null,
                opl_date_from_file: false,
                col_person_id_number: null,
                col_person_id_name: null,
                col_person_id_from_file: true,
                col_opl_date_number: null,
                col_opl_date_name: null,
                col_sum_number: null,
                col_sum_name: null,
                col_payer_number: null,
                col_payer_name: null,
                col_payer_from_file: true,
                col_purpose_of_payment_number: null,
                col_purpose_of_payment_name: null,
                opl_service_from_file: true,
                col_service_number: null,
                col_service_name: null,
                change_empty_service_by_all: false,
                service_by_code: true,
                service_id: null,
                services_conformity: []
              }
              this.importSetting = null

              const el = this.importSettings.find(item => item.value === data.id)
              if (el) {
                this.importSettings.splice(this.importSettings.indexOf(el), 1)
              }
              this.$store.commit(ALERT_SHOW, {text: 'Налаштування вилучено успішно'})
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
    },
    saveNewConfig() {
      this.showDialog = true
      this.createNew = true
    },
    saveConfig(event, from_dialog = false) {
      if (!from_dialog) {
        if (!this.importSetting) {
          this.showDialog = true
          this.createNew = true
          return;
        }
      }

      this.config_name = this.createNew ? this.config_name : this.importSettings.find(item => item.value === this.importSetting).text

      this.showDialog = false

      const payload = {
        data: Object.assign({}, this.config),
        id: this.createNew ? null : this.importSetting,
        name: this.config_name
      }
      fileImportAPI.save_or_update_payment_import_config(payload)
          .then(response => response.data)
          .then(data => {
            const obj = this.importSettings.find(item => item.value === data.id)
            if (!obj) {
              this.importSettings.push(
                  {text: data.name, value: data.id, data: data.config}
              )
              this.$store.commit(ALERT_SHOW, {text: 'Налаштування збережено успішно'})
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
          .finally(() => {
            this.createNew = false
            this.showDialog = false
          })
    },
    closeModal() {
      this.showDialog = false
      this.config_name = ''
    },
    bankChangeHeader(payload) {
      this.config.bank = payload.value
    },
    expandRow(row_num) {
      const row = this.table_items.find(item => item.row_num === row_num)
      if (row.service_id === this.all_services && !row.services.length) {
        this.changeService(this.all_services, row_num)
      }
      this.expand_row_num = this.expand_row_num === row_num ? 0 : row_num
    },
    selectRow(row_num) {
      if (row_num) {
        if (this.selectedRows.indexOf(row_num) > -1) {
          this.selectedRows = this.selectedRows.filter(item => item !== row_num)
        } else {
          this.selectedRows.push(row_num)
        }
      }
    },
    selectServiceRow(row_num) {
      if (row_num) {
        if (this.selectedServicesRows.indexOf(row_num) > -1) {
          this.selectedServicesRows = this.selectedServicesRows.filter(item => item !== row_num)
        } else {
          this.selectedServicesRows.push(row_num)
        }
      }
    },
    selectFilterRow(row_num) {
      if (row_num) {
        if (this.selectedFiltersRows.indexOf(row_num) > -1) {
          this.selectedFiltersRows = this.selectedFiltersRows.filter(item => item !== row_num)
        } else {
          this.selectedFiltersRows.push(row_num)
        }
      }
    },
    flatChange(payload) {
      const current_row = this.table_items.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.address = payload.address || ''
        current_row.person_id = payload.person_id
        current_row.payer = (payload.flat_owner || '').trim()
        current_row.flat_id = payload.value
        current_row.error = (!current_row.flat_id || !current_row.service_id || !current_row.bank)
      }

      this.$nextTick(() => {
        this.getTotals()
        // this.setPageSplitter()
      })
    },
    changeDate(payload) {
      if (payload) {
        const row = this.table_items.find(item => item.row_num === payload.row_num)
        if (row) {
          row.pay_date = payload.value
          row.error = (!row.flat_id || !row.service_id || !row.bank)
        }
      }
    },
    bankChange(payload) {
      if (payload) {
        const row = this.table_items.find(item => item.row_num === payload.row_num)
        if (row) {
          row.bank = payload.value
          row.error = (!row.flat_id || !row.service_id || !row.bank)
        }
      }
    },
    getTotals() {
      const totals = this.table_items.reduce((acc, item) => {
        acc.total += item.sum || 0
        acc.total_count += 1
        if (item.error) {
          acc.error += item.sum || 0
          acc.error_count += 1
        } else {
          acc.correct += item.sum || 0
          acc.correct_count += 1
        }
        return acc
      }, {total: 0, total_count: 0, correct: 0, correct_count: 0, error: 0, error_count: 0})

      this.total_sum = totals.total
      this.total_count = totals.total_count
      this.total_sum_correct = totals.total
      this.total_count_correct = totals.correct_count
      this.total_sum_error = totals.error
      this.total_count_error = totals.error_count
    },
    changeSum(row_num, id, sum) {
      if (!id) {
        this.splitSum(row_num, sum)
      } else {
        this.checkSumForSubItems(row_num)
      }
      this.getTotals()
    },
    doSplit(iterable, sum, operation = 'positive') {
      let sumForSplit = sum
      let total = 0
      let assign = operation === 'negative' ? -1 : 1
      let add = operation === 'equal' ? 100 : 0

      const func = {
        ['positive']: item => item.balance > 0,
        ['negative']: item => item.balance < 0,
        ['equal']: item => item.balance === 0
      }

      iterable.forEach(item => item.sum = 0)

      const filter_array = iterable.filter(func[operation]).map(item => {
        total += item.balance * assign + add
        return {id: item.id, balance: item.balance * assign + add, result: 0}
      })

      if (operation === 'negative') {
        const reversed = filter_array.sort((a, b) => a.balance - b.balance).reverse()

        reversed.forEach((item, index) => {
          filter_array[index].id = item.id
        })
      }

      filter_array.forEach(item => {
        const k = sumForSplit / total
        item.result = +(item.balance * k).toFixed(2) || 0

        sumForSplit -= item.result
        total -= item.balance

        const i = iterable.find(it => it.id === item.id)
        if (i) {
          i.sum = item.result
        }
      })

      sumForSplit = +(sumForSplit.toFixed(2))

      return sumForSplit

    },
    splitSum(row_num, sum) {
      const current_row = this.table_items.find(item => item.row_num === row_num)
      let sumForSplit = this.doSplit(current_row.services, sum, 'positive')
      if (sumForSplit > 0) {
        sumForSplit = this.doSplit(current_row.services, sum, 'negative')
      }
      if (sumForSplit > 0) {
        sumForSplit = this.doSplit(current_row.services, sum, 'equal')
      }
    },
    changeService(service_id, row_num) {
      const row = this.table_items.find(item => item.row_num === row_num)
      const all_service_id = (this.settings.all_services || {}).value || 0

      if (row) {
        const service_row = this.services.find(item => item.value === service_id)
        if (service_row) {
          row.icon = service_row.type
        }

        if (row.services.length && service_id === all_service_id) {
          this.splitSum(row_num, row.sum)
        } else if (row.services.length && service_id !== all_service_id) {
          row.services = []
        } else if (!row.services.length && service_id === all_service_id) {
          this.get_services_for_split({header_id: 1, flat_id: row.flat_id})
              .then(response => {
                if (response.length === 0) {
                  row.services = []
                  row.service_id = null
                } else {
                  row.services = response
                }
                this.splitSum(row_num, row.sum)
              })
              .catch(() => {
                row.services = [
                  {
                    id: 0,
                    pay_date: this.header_date,
                    service_id: 0,
                    bank: this.header_bank,
                    sum: 0,
                    balance: 0,
                    icon: ''
                  }
                ]
              })
              .finally(() => {
                this.splitSum(row_num, row.sum)
              })
        }
      }
      this.getTotals()
    },
    addNewRow() {
      const max_row_item = maxBy(this.table_items, 'row_num')
      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      const new_row = {
        id: 0,
        flat_id: 0,
        pay_date: this.config.opl_date || null,
        sum: 0,
        bank: this.config.bank || null,
        payer: "",
        service_id: this.config.service_id || null,
        is_group: false,
        services: [],
        balance: 0,
        icon: "",
        person_id: "",
        address: "",
        row_num: max_row + 1,
        error: true
      }
      this.table_items.push(new_row)
      this.setPageSplitter()
      this.page = this.pages
    },
    addNewServiceRow() {
      const max_row_item = maxBy(this.config.services_conformity, 'row_num')
      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      const new_row = {
        code: null,
        name: '',
        service_id: null,
        row_num: max_row + 1
      }
      this.config.services_conformity.push(new_row)
    },
    addNewFilterRow() {
      const max_row_item = maxBy(this.config.filters, 'row_num')
      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      const new_row = {
        col_number: null,
        col_name: '',
        col_type: 'string',
        operation: null,
        value_from: null,
        value_to: null,
        row_num: max_row + 1
      }
      this.config.filters.push(new_row)
    },
    deleteRows() {
      this.selectedRows.forEach(item => {
        const tab_item = this.table_items.find(tab_item => tab_item.row_num === item)
        if (tab_item) {
          const idx = this.table_items.indexOf(tab_item)
          this.table_items.splice(idx, 1)
        }
      })
      this.table_items.forEach((item, idx) => {
        item['row_num'] = idx + 1
      })
      this.selectedRows = []
      this.getTotals()
    },
    deleteRowsFromServices() {
      this.selectedServicesRows.forEach(item => {
        const service_item = this.config.services_conformity.find(service_item => service_item.row_num === item)
        if (service_item) {
          const idx = this.config.services_conformity.indexOf(service_item)
          this.config.services_conformity.splice(idx, 1)
        }
      })
      // this.config.services_conformity.forEach((item, idx) => {
      //   item['row_num'] = idx + 1
      // })
      this.selectedServicesRows = []
    },
    deleteRowsFromFilters() {
      this.selectedFiltersRows.forEach(item => {
        const find_item = this.config.filters.find(filter_item => filter_item.row_num === item)
        if (find_item) {
          const idx = this.config.filters.indexOf(find_item)
          this.config.filters.splice(idx, 1)
        }
      })
      // this.config.services_conformity.forEach((item, idx) => {
      //   item['row_num'] = idx + 1
      // })
      this.selectedFiltersRows = []
    },

    get_services_for_split(payload) {
      return new Promise((resolve, reject) => {
        paymentAPI.get_services_for_sub_item(payload)
            .then(response => response.data)
            .then(data => {
              let idx = 0
              data.forEach(item => {
                idx += 1
                if (item.id === 0) {
                  item.pay_date = this.header_date
                  item.bank = this.header_bank
                  item.id = idx
                }
              })
              resolve(data)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
              reject(false)
            })
      })
    },
    checkSumForSubItems(row_num) {
      const row = this.table_items.find(item => item.row_num === row_num)
      if (row) {
        const child_sum = row.services.reduce((total, el) => {
          return el.sum + total
        }, 0)
        row.error = row.sum !== child_sum;
      }
    },
    saveDocument() {
      const rows = []

      this.table_items.forEach(item => {
        if (!item.edited) {
          rows.push(
              {
                header_id: 1,
                flat_id: item.flat_id,
                pay_date: item.pay_date,
                sum: item.sum,
                bank: item.bank,
                payer: item.payer,
                service_id: item.service_id,
                is_group: false,
                edited: item.edited || false,
                services: []
              })
        } else {
          rows.push(
              {
                header_id: 1,
                flat_id: item.flat_id,
                pay_date: item.pay_date,
                sum: item.sum,
                bank: item.bank,
                payer: item.payer,
                service_id: item.service_id,
                is_group: false,
                edited: item.edited || false,
                services: item.services.map(s => {
                  return {service_id: s.service_id, sum: s.sum}
                })
              })
        }
      })

      const is_create = true

      const payload = {
        header: {
          pay_date: null,
          total_sum: this.total_sum,
          total_row: this.table_items.length,
          bank: this.config.bank,
          by_bank: true,
          date: formatDate(Date(), 'YYYY-MM-DD HH:mm:ss'),
          month: this.month,
          comment: `Завантажено із ${this.inputFile.name}`
        },
        table: rows
      }

      if (is_create) {
        paymentAPI.create(payload)
            .then(response => response.data)
            .then(data => {
              if (data) {
                const header_id = +data
                this.$store.commit(ALERT_SHOW, {text: 'Оплати успішно створено', color: 'success'})
                this.$router.push(
                    {
                      name: 'PaymentDocumentHeader',
                      params: {header_id: header_id}
                    }
                )
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      }
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.table_items = []
                  this.total_sum = 0
                }
              }
              if (payload.id === 'delete_config') {
                this.deleteConfig()
              }
            }
          }
      )
    },
  },
  created() {
    this.getConfig()
    this.watch_modal_answer()
  }
}
</script>

<style scoped lang="scss">
.custom-subheader {
  padding: 0 !important;
}
.with-dence-input {
    :deep(.small-input) {
      &.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
        max-height: 47px !important;
      }

      &.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
        max-height: 40px !important;
        min-height: 40px !important;
      }

      &.v-text-field--filled .v-label--active, .v-text-field--full-width .v-label--active {
        top: 11px !important;
      }

      &.v-text-field--filled .v-label, .v-text-field--full-width .v-label {
        top: 15px !important;
      }

      .v-input__slot {
        max-height: 40px !important;
      }

      .v-label {
        font-size: 14px !important;
      }

      .v-text-field__slot {
        input {
          margin-top: 4px !important;
          font-size: .9rem !important;
          //max-height: 28px !important;
        }
      }
    }
}

.pay-table-wrapper .pay-table {
  font-size: .76rem !important;
}

.pay-table-wrapper .pay-table .editable {
  padding: 5px 2px !important;
  line-height: .9rem;
}

.pay-table-col {
  padding: 5px 2px !important;
  line-height: .9rem;

  &:nth-child(1) {
    flex: 0 1 60px;
    padding-left: 16px !important;
    min-width: 60px;
  }

  &:nth-child(2) {
    flex: 0 1 50px;
    min-width: 50px;
  }

  &:nth-child(3) {
    flex: 0 1 130px;
    min-width: 130px;
  }

  &:nth-child(4) {
    flex: 0 1 340px;
    min-width: 300px;
  }

  &:nth-child(5) {
    flex: 0 1 230px;
    min-width: 200px;
  }

  &:nth-child(6) {
    flex: 0 1 115px;
    min-width: 115px;
  }
  //
  //&:nth-child(7) {
  //  flex: 0 1 190px;
  //  min-width: 150px;
  //}

  &:nth-child(7) {
    flex: 0 1 100px;
    min-width: 100px;
  }

  &:nth-child(8) {
    flex: 0 1 110px;
    min-width: 110px;
  }

  &:nth-child(9) {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    min-width: 180px;
  }
  &:nth-child(10) {
    flex: 0 1 40px;
    min-width: 40px;
  }
}

.pay-table-row-expand-body {
  display: none;
  max-height: 0;

  .pay-table-expand-body-row {
    .pay-table-col {
      &:nth-child(1) {
        flex: 0 1 925px;
        min-width: 855px;
      }

      &:nth-child(2) {
        flex: 0 1 100px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        min-width: 100px;
      }

      &:nth-child(3) {
        flex: 0 1 110px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        min-width: 110px;
      }

      &:nth-child(4) {
        flex: 1;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        min-width: 180px;
      }
      &:nth-child(5) {
        flex: 0 1 40px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        min-width: 40px;
      }
    }
  }
}

.col-error {
  border-left: 6px solid #bb2f2f !important;
}
.flex-50 {
  flex: 0 0 50%;
}
.flex-100 {
  flex: 1;
}
</style>